import React, { useState, useEffect, useRef } from 'react';
import { Typewriter } from 'react-simple-typewriter';
import { Container, Row, Col } from 'react-bootstrap';
import { FaArrowDown } from 'react-icons/fa'; // Import the scroll icon
import './Home.css'; 

export default function Home() {
  const [isTyped, setIsTyped] = useState(false);
  const [myCursor, setMyCursor] = useState("|");
  const [isSectionVisible, setIsSectionVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setMyCursor("");
      setIsTyped(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const currentSectionRef = sectionRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsSectionVisible(true);
          } else {
            setIsSectionVisible(false);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (currentSectionRef) {
      observer.observe(currentSectionRef);
    }

    return () => {
      if (currentSectionRef) {
        observer.unobserve(currentSectionRef);
      }
    };
  }, []);

  return (
    <div>
      <Container fluid className="d-flex vh-100 justify-content-center align-items-center bg-dark text-white">
        <Row>
          <Col className="text-center">
            <h1 className={`typewriter-text ${isSectionVisible ? 'shrink' : ''}`} style={styles.typing}>
              <Typewriter
                words={['Hello world, my name is Joe OConnell']}
                cursor
                cursorStyle={myCursor}
                loop={1}
                typeSpeed={70}
                delaySpeed={1000}
              />
            </h1>
            {isTyped && (
              <h3 className={`typewriter-text ${isSectionVisible ? 'shrink' : ''}`} style={styles.subText}>
                <Typewriter
                  words={['I am a student and aspiring software engineer']}
                  cursor
                  cursorStyle="|"
                  loop={1}
                  typeSpeed={70}
                  delaySpeed={1000}
                />
              </h3>
            )}
            <div className="scroll-icon">
              <FaArrowDown />
            </div>
          </Col>
        </Row>

      </Container>
      <Container fluid ref={sectionRef} className="d-flex vh-100 justify-content-center align-items-center bg-light text-dark">
        <Row>
          <Col className="text-center">
            <h1 className={`typewriter-text ${isSectionVisible ? 'grow' : 'small'}`} style={styles.typing}>
              Welcome to my portfolio!
            </h1>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const styles = {
  typing: {
    fontSize: '3em',
    transition: 'font-size 0.5s ease',
  },
  subText: {
    fontSize: '2em',
    marginTop: '20px',
    transition: 'font-size 0.5s ease',
  },
};
